<template>
	<div class="pushNotificationContainer">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a', fontWeight: 'bold', fontSize: '20px', marginBottom: '20px' }"> Push Notification </a-breadcrumb-item>
			</a-breadcrumb>
		</div>
		<div class="mainContainer">
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Users </span>
				</div>
				<!-- <div class="detailsContainer">
					<a-form-item label="All Users"> </a-form-item>
					<span>
						<a-switch v-model:checked="allUser" @change="handleAllUserChange" />
					</span>
				</div> -->
				<div class="detailsContainer">
					<a-form-item label="Select user"> </a-form-item>
					<span>
						<div class="inputContainer">
							<div style="display: flex">
								<a-checkbox v-model:checked="allUser" class="status_checkbox" @click="statusActiveOnClickAll">All</a-checkbox>
								<a-checkbox v-model:checked="dormantUser" class="status_checkbox" @click="statusActiveOnClickDormant">Dormant</a-checkbox>
								<a-checkbox v-model:checked="activeUser" class="status_checkbox" @click="statusActiveOnClickActive">Active</a-checkbox>
								<a-checkbox v-model:checked="inCompleteUser" class="status_checkbox" @click="statusActiveClickInComplete">Incomplete</a-checkbox>
							</div>
						</div>
					</span>
				</div>
				<div class="detailsContainer">
					<a-form-item label="Users Id"> </a-form-item>
					<span>
						<div class="inputContainer">
							<!-- <a-button :disabled="allUser" @click="showInput" class="addIdBtn">+ userID</a-button> -->
							<a-textarea :disabled="allUser" v-model:value="userIdInputValue" @keyup.enter="handleBulkInput" placeholder="Paste User IDs here and press Enter" rows="5" class="textAreaAll"></a-textarea>
						</div>
					</span>
				</div>

				<div class="selectedLocationContainer">
					<div v-for="tag in userIdTag" class="tag">
						<a-tag closable @click="handleClose(tag)" class="userIdTag">
							{{ tag }}
						</a-tag>
					</div>
					<a-input v-if="inputVisible" v-model:value="userIdInputValue" @blur="handleInputConfirm" @keyup.enter="handleInputConfirm" class="userIdBtn" />
				</div>
			</div>
		</div>

		<div v-show="!allUser" class="otherFieldsContainer">
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Age range</span>
				</div>
				<div class="detailsContainer">
					<a-form-item label="Age range"></a-form-item>
					<span style="display: flex; gap: 10px">
						<a-input v-model:value="fromAgeValue" type="number" :min="18" :max="100" step="0" addon-before="from" style="width: 122px" />
						<a-input v-model:value="toAgeValue" type="number" step="0" :min="18" :max="100" addon-before="to" style="width: 122px" />
					</span>
				</div>
			</div>
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Gender</span>
				</div>
				<div style="display: flex; justify-content: space-between">
					<a-form-item label="Gender"></a-form-item>
					<span>
						<a-checkbox-group v-model:value="selectGender" class="checkbox-container">
							<div style="display: flex">
								<a-checkbox :value="'m'" class="checkbox male-checkbox">Male</a-checkbox>
								<a-checkbox :value="'f'" class="checkbox">Female</a-checkbox>
							</div>
						</a-checkbox-group>
					</span>
				</div>
			</div>
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Location</span>
				</div>
				<div class="detailsContainer">
					<a-form-item label="City"></a-form-item>
					<span>
						<a-select v-model:value="currentCity" allow-clear show-search @change="handleCityChange" :options="cityItems" @search="searchCityForDropDown" class="dropDown">
							<template v-slot:notFoundContent>
								<Spin size="small" v-if="loading" />
								<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
							</template>
						</a-select>
					</span>
				</div>
				<div class="selectedLocationContainer" v-if="selectedCities.length > 0" :style="{ 'margin-bottom': '20px' }">
					<span v-for="city in selectedCities">
						<a-tag closable @click="handleCityTagClose(city)" class="locationTag">{{ city }}</a-tag>
					</span>
				</div>
				<div class="detailsContainer">
					<a-form-item label="State/Province"></a-form-item>
					<span>
						<a-select v-model:value="currentState" allow-clear show-search :options="stateItems" @change="handleStateChange" @search="searchStateForForDropDown" class="dropDown" auto>
							<template v-slot:notFoundContent>
								<Spin size="small" v-if="loading" />
								<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
							</template>
						</a-select>
					</span>
				</div>
				<div class="selectedLocationContainer" v-if="selectedState.length > 0" :style="{ 'margin-bottom': '20px' }">
					<span v-for="state in selectedState">
						<a-tag closable @click="handleStateTagClose(state)" class="locationTag">{{ state }}</a-tag>
					</span>
				</div>
				<div class="detailsContainer">
					<a-form-item label="Country"></a-form-item>
					<span>
						<a-select v-model:value="currentCountry" allow-clear show-search :options="countryItems" @change="handleCountryChange" @search="searchCountryForDropDown" class="dropDown">
							<template v-slot:notFoundContent>
								<Spin size="small" v-if="loading" />
								<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
							</template>
						</a-select>
					</span>
				</div>
				<div class="selectedLocationContainer" v-if="selectedCountry.length > 0" :style="{ 'margin-bottom': '20px' }">
					<span v-for="country in selectedCountry">
						<a-tag closable @click="handleCountryClose(country)" class="locationTag">{{ country }}</a-tag>
					</span>
				</div>
			</div>
		</div>

		<div class="notificationContainer">
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Notification</span>
				</div>
				<div class="detailsContainer">
					<a-form-item label="Notification Redirection Key"> </a-form-item>
					<span>
						<a-select v-model:value="selectedNotificationKey" @change="handleSelectChangeNotifiCationKey" class="dropDown">
							<a-select-option v-for="notification in notifications" :key="notification.key" :value="notification.key">
								{{ notification.title }}
							</a-select-option>
						</a-select>
					</span>
				</div>
				<div class="detailsContainer" :style="{ 'margin-bottom': '35px' }">
					<a-form-item label="Notification Bar Heading"> </a-form-item>
					<a-textarea rows="2" class="textArea" showCount :maxlength="200" v-model:value="notificationHeading" :class="{ 'show-count-bottom-right': true }"></a-textarea>
				</div>
				<div class="detailsContainer" :style="{ 'margin-bottom': '35px' }">
					<a-form-item label="Notification Bar Text" class="dropDown"> </a-form-item>
					<a-textarea rows="2" class="textArea" showCount :maxlength="200" v-model:value="notificationText" :class="{ 'show-count-bottom-right': true }"></a-textarea>
				</div>
				<div class="detailsContainer" :style="{ 'margin-bottom': '35px' }">
					<a-form-item label="Notification History Description" class="dropDown"> </a-form-item>
					<a-textarea rows="6" class="textArea" v-model:value="notificationDescription"></a-textarea>
				</div>
			</div>
		</div>

		<div class="btnContainer">
			<a-button type="primary" class="clearBtn" @click="clearForm">Clear</a-button>
			<a-button type="primary" class="saveBtn" @click="collectAndSendData">Send Notification</a-button>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { createVNode, ref } from "vue";
	import { addLocationForNotification, sendManualNotification, getNotificationKey } from "@/services/api/user";
	import { Empty, Modal, Spin } from "ant-design-vue";
	import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
	import { openNotificationWithIcon } from "@/utils";

	interface Notification {
		title: string;
		key: string;
	}

	const handleBulkInput = () => {
		if (!userIdInputValue.value.trim()) return;

		const newIds = userIdInputValue.value
			.split(/[\n, ]+/)
			.map((id) => id.trim())
			.filter((id) => id && !userIdTag.value.includes(id)); // Avoid duplicates

		userIdTag.value.push(...newIds);

		userIdInputValue.value = "";
	};

	const loading = ref(false);
	const allUser = ref(true);
	const currentCity = ref("");
	const cityItems = ref([]);
	const selectedCities = ref<string[]>([]);
	const currentState = ref("");
	const stateItems = ref([]);
	const selectedState = ref<string[]>([]);
	const currentCountry = ref("");
	const countryItems = ref([]);
	const selectedCountry = ref<string[]>([]);
	const fromAgeValue = ref();
	const toAgeValue = ref();
	const selectGender = ref("");
	const notificationHeading = ref("");
	const notificationText = ref("");
	const notificationDescription = ref("");
	const userIdTag = ref<string[]>([]);
	const inputVisible = ref(false);
	const userIdInputValue = ref("");
	const notifications = ref<Notification[]>([]);
	const selectedNotificationKey = ref("");
	const activeUser = ref(true);
	const dormantUser = ref(true);
	const inCompleteUser = ref(true);
	const handleAllUserChange = (checked: boolean) => {
		if (checked) {
			userIdTag.value = [];
			inputVisible.value = false;
		}
	};

	const searchCityForDropDown = async (value: string) => {
		if (value.length < 3) return;
		loading.value = true;
		try {
			const response = await addLocationForNotification({
				searchBy: "city",
				search: value,
			});
			cityItems.value = response.data.map((city: string) => ({ value: city, label: city }));
			loading.value = false;
		} catch (error) {
			console.error(error);
			loading.value = false;
		}
	};

	const searchStateForForDropDown = async (value: string) => {
		if (value.length < 3) return;
		try {
			loading.value = true;
			const response = await addLocationForNotification({
				searchBy: "region",
				search: value,
			});
			stateItems.value = response.data.map((state: string) => ({ value: state, label: state }));
			loading.value = false;
		} catch (error) {
			console.error(error);
			loading.value = false;
		}
	};

	const searchCountryForDropDown = async (value: string) => {
		if (value.length < 3) return;
		try {
			loading.value = true;
			const response = await addLocationForNotification({
				searchBy: "country",
				search: value,
			});
			countryItems.value = [];
			countryItems.value = response.data.map((country: string) => ({ value: country, label: country }));
			loading.value = false;
		} catch (error) {
			console.error(error);
			loading.value = false;
		}
	};

	const statusActiveOnClickAll = () => {
		if (allUser.value) {
			activeUser.value = false;
			dormantUser.value = false;
			inCompleteUser.value = false;
			return;
		} else {
			activeUser.value = true;
			dormantUser.value = true;
			inCompleteUser.value = true;
			return;
		}
	};

	const statusActiveOnClickDormant = () => {
		if (dormantUser.value === false && activeUser.value === true && inCompleteUser.value == true) {
			dormantUser.value = true;
			allUser.value = true;
			return;
		}
		if (dormantUser.value === true && activeUser.value === true && inCompleteUser.value == true && allUser.value == true) {
			allUser.value = false;
			dormantUser.value = false;
			return;
		}
		dormantUser.value = true;
	};

	const statusActiveOnClickActive = () => {
		if (dormantUser.value === true && activeUser.value === false && inCompleteUser.value == true) {
			activeUser.value = true;
			allUser.value = true;
			return;
		}
		if (dormantUser.value === true && activeUser.value === true && inCompleteUser.value == true && allUser.value == true) {
			allUser.value = false;
			activeUser.value = false;
			return;
		}
		activeUser.value = true;
	};

	const statusActiveClickInComplete = () => {
		if (dormantUser.value === true && activeUser.value === true && inCompleteUser.value == false) {
			inCompleteUser.value = true;
			allUser.value = true;
			return;
		}
		if (dormantUser.value === true && activeUser.value === true && inCompleteUser.value == true && allUser.value == true) {
			allUser.value = false;
			inCompleteUser.value = false;
			return;
		}
		inCompleteUser.value = true;
	};

	const handleCityChange = (value: string) => {
		if (value && !selectedCities.value.includes(value)) {
			selectedCities.value.push(value);
		}
		currentCity.value = "";
	};

	const handleCityTagClose = async (cityToRemove: string) => {
		const index = selectedCities.value.indexOf(cityToRemove);
		selectedCities.value.splice(index, 1);
		selectedCities.value = [...selectedCities.value];
	};

	const handleStateChange = (value: any) => {
		if (value && !selectedState.value.includes(value)) {
			selectedState.value.push(value);
		}
		currentState.value = "";
	};

	const handleStateTagClose = async (stateToRemove: string) => {
		const index = selectedState.value.indexOf(stateToRemove);
		selectedState.value.splice(index, 1);
		selectedState.value = [...selectedState.value];
	};

	const handleCountryChange = (value: any) => {
		if (value && !selectedCountry.value.includes(value)) {
			selectedCountry.value.push(value);
		}
		currentCountry.value = "";
	};

	const handleCountryClose = async (countryToRemove: string) => {
		const index = selectedCountry.value.indexOf(countryToRemove);
		selectedCountry.value.splice(index, 1);
		selectedCountry.value = [...selectedCountry.value];
	};

	const collectAndSendData = async () => {
		if (!selectedNotificationKey.value) {
			openNotificationWithIcon("error", "Please select Notification Key");
			return;
		}
		if (!notificationHeading.value) {
			openNotificationWithIcon("error", "Please enter Notification Heading");
			return;
		}
		if (!notificationText.value) {
			openNotificationWithIcon("error", "Please enter Notification Text");
			return;
		}

		// if (fromAgeValue.value < 18) {
		// 	openNotificationWithIcon("error", "From Age Value should be greater than or equal to  18");
		// 	return;
		// }

		if (!fromAgeValue.value && toAgeValue.value) {
			openNotificationWithIcon("error", "Please enter From Age Value");
			return;
		}

		if (fromAgeValue.value && !toAgeValue.value) {
			openNotificationWithIcon("error", "Please enter To Age Value");
			return;
		}
		if (userIdTag.value.length == 0 && allUser.value === false && dormantUser.value === false && activeUser.value === false && inCompleteUser.value === false) {
			openNotificationWithIcon("error", "Please Mark at lease one field from status");
		}
		if (!allUser.value && userIdTag.value.length == 0 && selectedCities.value.length == 0 && selectedState.value.length == 0 && selectedCountry.value.length == 0 && !fromAgeValue.value && !toAgeValue.value && !selectGender.value && !dormantUser.value && !activeUser.value && !inCompleteUser.value) {
			openNotificationWithIcon("error", "Please Mark at lease one field to send notification");
			return;
		}
		if (activeUser.value && activeUser.value && inCompleteUser.value) {
			allUser.value === true;
		}
		Modal.confirm({
			title: "Are you sure want to Send Notification?",
			icon: createVNode(ExclamationCircleOutlined),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",

			onOk: async () => {
				const data = {
					allUser: allUser.value,
					cities: selectedCities.value,
					states: selectedState.value,
					countries: selectedCountry.value,
					fromAgeValue: fromAgeValue.value || "",
					toAgeValue: toAgeValue.value || "",
					gender: selectGender.value,
					notificationTitle: notificationHeading.value,
					notificationBody: notificationText.value,
					userIds: userIdTag.value,
					notificationKey: selectedNotificationKey.value,
					notificationDescription: notificationDescription.value || "",
					activeUser: activeUser.value,
					dormantUser: dormantUser.value,
					inCompleteUser: inCompleteUser.value,
				};
				await sendManualNotification(data);
				clearForm();
			},
		});
	};

	const clearForm = () => {
		allUser.value = false;
		currentCity.value = "";
		cityItems.value = [];
		selectedCities.value = [];
		currentState.value = "";
		stateItems.value = [];
		selectedState.value = [];
		currentCountry.value = "";
		countryItems.value = [];
		selectedCountry.value = [];
		fromAgeValue.value = "";
		toAgeValue.value = "";
		selectGender.value = "";
		notificationHeading.value = "";
		notificationText.value = "";
		userIdTag.value = [];
		selectedNotificationKey.value = "";
		notificationDescription.value = "";
		inCompleteUser.value = false;
		dormantUser.value = false;
		activeUser.value = false;
	};

	const handleClose = (removedTag: any) => {
		userIdTag.value = userIdTag.value.filter((tag) => tag !== removedTag);
	};

	const showInput = () => {
		inputVisible.value = true;
	};

	const handleInputConfirm = () => {
		if (userIdInputValue.value && !userIdTag.value.includes(userIdInputValue.value)) {
			userIdTag.value.push(userIdInputValue.value);
		}
		inputVisible.value = false;
		userIdInputValue.value = "";
	};

	const fetchNotificationKey = async () => {
		try {
			loading.value = true;
			const apiRes = await getNotificationKey();
			notifications.value = apiRes.key;
			loading.value = false;
		} catch (error) {
			console.error("Error fetching data:", error);
			loading.value = false;
		}
	};
	fetchNotificationKey();

	const handleSelectChangeNotifiCationKey = (value: string) => {
		selectedNotificationKey.value = value;
	};
</script>

<style scoped>
	.pushNotificationContainer {
		padding: 20px 100px;
	}
	.table-operations {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
	}
	.mainContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 5px;
	}
	.mainDiv {
		padding: 20px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 6px;
		width: 100%;
		margin-bottom: 35px;
	}
	.subDiv {
		display: flex;
		position: absolute;
		top: -11px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
		font-size: 15px;
	}
	.detailsContainer {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}
	.selectedLocationContainer {
		display: flex;
		flex-wrap: wrap; /* This line enables the wrapping behavior */
		margin-right: 100px;
		margin-bottom: 20px;
	}
	.otherFieldsContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 5px;
	}

	.notificationContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 5px;
	}
	.dropDown {
		width: 14rem;
	}
	.locationInput {
		width: 20rem;
	}
	.textArea {
		width: 35rem;
		height: 3rem;
	}
	.textAreaAll {
		width: 35rem;
	}
	.notificationUrl {
		width: 35rem;
		height: 3rem;
	}
	.locationTag {
		padding: 5px 16px;
		text-align: center;
		font-size: 15px;
		margin-bottom: 15px;
		cursor: pointer;
	}
	.userIdTag {
		padding: 5px 16px;
		text-align: center;
		font-size: 15px;
		margin-bottom: 15px;
		cursor: pointer;
		display: flex;
	}
	.inputContainer {
		position: relative;
		margin-bottom: 15px;
	}
	.addIdBtn {
		margin-bottom: 10px;
		width: 100px;
		position: absolute;
		right: 0;
	}
	.userIdBtn {
		width: 200px;
		height: 33px;
	}
	.btnContainer {
		display: flex;
		justify-content: center;
		gap: 20px;
	}
	.checkbox {
		padding: 6px 65px 6px 19px;
		border-radius: 5px;
		width: 100%;
		border: 1px solid #d5d5d5;
		margin: 0px 5px;
	}
	.status_checkbox {
		border: 1px solid #d5d5d5;
		border-radius: 5px;
		padding: 6px 30px 6px 19px;
		margin: 0px 5px;
	}
</style>

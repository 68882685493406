<template>
	<div class="analyticsContainer">
		<div>
			<div class="mainDiv" :style="{ marginBottom: '60px' }">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">User Actions</span>
				</div>
				<div class="chartContainerDropDown">
					<div class="chartContainerDropDownVisitOption">
						<span>Activity Type : </span>
						<a-select v-model:value="visitType" :style="{ width: '200px', marginLeft: '10px' }" @change="handleVisitTypeChange">
							<a-select-option value="chatbot_visit">Romeu</a-select-option>
							<a-select-option value="astro_visit">Astro Compatibility</a-select-option>
							<a-select-option value="jugnian_visit"> MBTI Compatibility</a-select-option>
							<a-select-option value="luckytime_visit">Luckytime </a-select-option>
							<a-select-option value="matchMeUp">MatchMeUp</a-select-option>
						</a-select>
					</div>
					<div :style="{ display: 'flex', gap: '10px', alignItems: 'start' }">
						<div v-if="timeRange === 'month'">
							<a-month-picker v-model:value="monthRangeValue" @change="handleMonthChange" format="MMM YYYY" />
						</div>

						<div v-else>
							<a-form-item :style="{ marginBottom: '0px' }">
								<a-space direction="vertical" :size="12" :style="{ marginBottom: '0px' }">
									<a-range-picker v-model:value="dateRange" :disabled-date="disableSecondDate" format="DD-MMM-YYYY" @change="handleDateChange" :disabled="[false, true]" />
								</a-space>
							</a-form-item>
						</div>

						<div class="selectOptions">
							<a-select v-model:value="timeRange" style="width: 120px" @change="handleTimeChange">
								<a-select-option value="month">Month</a-select-option>
								<a-select-option value="day">Day</a-select-option>
							</a-select>
						</div>
					</div>
				</div>
				<div class="deletedAccountChartContainer">
					<div class="featureAnalytics">
						<Line :data="featuresAccountChartData" :options="featuresCountChartOptions" />
					</div>
				</div>
			</div>

			<div class="mainDiv" :style="{ marginBottom: '60px' }">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">User Actions Distribution</span>
				</div>
				<div class="chart-wrapper">
					<div class="pie-chart-container">
						<Pie :data="featureAnalyticsPieChartData" :options="featuredAnalyticsPieChartOptions" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ref } from "vue";
	import { Bar } from "vue-chartjs";
	import { Line, Pie } from "vue-chartjs";
	import ChartDataLabels from "chartjs-plugin-datalabels";
	import { Chart, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, PieController, ArcElement } from "chart.js";
	import { visitedCount } from "@/services/api/user";
	import { ChartOptions } from "chart.js";
	import { openNotificationWithIcon, removeNullFromObj } from "@/utils";
	import dayjs from "dayjs";
	Chart.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, PieController, ArcElement, ChartDataLabels);

	const loading = ref(false);
	const visitType: any = ref("chatbot_visit");
	const dateRange: any = ref(getDefaultDateRange());
	const timeRange: any = ref("day");
	const monthRangeValue: any = ref("");

	function getDefaultDateRange() {
		const today = dayjs();
		const twentyDaysAgo = dayjs().subtract(29, "day");
		return [twentyDaysAgo, today];
	}

	const disableSecondDate = (current: dayjs.Dayjs) => {
		return dateRange.value[1] && current.isSame(dateRange.value[1], "day");
	};

	const handleDateChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
		if (dates && dates[0]) {
			const startDate = dates[0];
			const endDate = startDate.add(19, "day");
			dateRange.value = [startDate, endDate];
			fetchFeaturesCountData();
		} else {
			dateRange.value = getDefaultDateRange();
		}
	};

	const featuresCountChartOptions: ChartOptions<"line"> = {
		responsive: true,
		layout: {
			padding: {
				top: 27,
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: "Total Number of Visitors",
					font: {
						size: 20,
					},
				},
				ticks: {
					callback: function (value) {
						if (Number.isInteger(Number(value))) {
							return value;
						}
					},
				},
			},
		},
		plugins: {
			tooltip: {
				enabled: false,
			},
			datalabels: {
				anchor: "end",
				align: "end",
				formatter: (value: any, context: any) => {
					// Only show label if value is greater than 0 and is integer
					return value > 0 ? Math.round(value) : "";
				},
				font: {
					size: 12,
				},
			},
			legend: {
				display: false,
				position: "bottom",
			},
		},
	};

	const featuresAccountChartData = ref({
		labels: [],
		type: "line",
		datasets: [
			{
				label: "Astro Visit",
				data: [],
				backgroundColor: "rgba(54, 162, 235, 1)",
				borderColor: "rgba(54, 162, 235, 1)",
				fill: false,
				pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
				pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
				pointRadius: 2,
			},
			{
				label: "Jugnian Visit",
				data: [],
				backgroundColor: "rgba(255, 99, 132, 1)",
				borderColor: "rgba(255, 99, 132, 1)",
				fill: false,
				pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
				pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
				pointRadius: 2,
			},
			{
				label: "Chatbot Visit",
				data: [],
				backgroundColor: "rgba(75, 192, 192, 1)",
				borderColor: "rgba(75, 192, 192, 1)",
				fill: false,
				pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
				pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
				pointRadius: 2,
			},
			{
				label: "luckytime",
				data: [],
				backgroundColor: "rgba(75, 192, 192, 1)",
				borderColor: "rgba(75, 192, 192, 1)",
				fill: false,
				pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
				pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
				pointRadius: 2,
			},
			{
				label: "matchMeUp",
				data: [],
				backgroundColor: "rgba(75, 192, 192, 1)",
				borderColor: "rgba(75, 192, 192, 1)",
				fill: false,
				pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
				pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
				pointRadius: 2,
			},
		],
	});

	const fetchFeaturesCountData = async () => {
		try {
			loading.value = true;
			if (timeRange.value === "month" && !monthRangeValue.value) {
				return openNotificationWithIcon("error", "Please select a month range");
			}
			let payload: any = {
				visitType: visitType.value,
				forTime: timeRange.value,
			};

			if (timeRange.value === "month") {
				payload.monthRange = monthRangeValue.value ? dayjs(monthRangeValue.value).format("YYYY-MM") : null;
				payload.fromDate = null;
				payload.toDate = null;
			} else {
				payload.fromDate = dateRange.value[0].format("YYYY-MM-DDT00:00:00.SSS[Z]");
				payload.toDate = dateRange.value[1].format("YYYY-MM-DDT00:00:00.SSS[Z]");
				payload.monthRange = null;
			}
			const apiRes = await visitedCount(payload);
			if (apiRes && apiRes.data.length > 0) {
				const labels = apiRes.data.map((item: any) => {
					const date = dayjs(item.date);
					return date.format("DD MMM");
				});

				featuresAccountChartData.value = {
					labels: labels,
					type: "line",
					datasets: [
						{
							label: "Astro Visit",
							data: apiRes.data.map((item: any) => item.astro_visit),
							backgroundColor: "rgba(54, 162, 235, 1)",
							borderColor: "rgba(54, 162, 235, 1)",
							fill: false,
							pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
							pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
							pointRadius: 2,
						},
						{
							label: "Jugnian Visit",
							data: apiRes.data.map((item: any) => item.jugnian_visit),
							backgroundColor: "rgba(255, 99, 132, 1)",
							borderColor: "rgba(54, 162, 235, 1)",
							fill: false,
							pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
							pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
							pointRadius: 2,
						},
						{
							label: "Chatbot Visit",
							data: apiRes.data.map((item: any) => item.chatbot_visit),
							backgroundColor: "rgba(75, 192, 192, 1)",
							borderColor: "rgba(54, 162, 235, 1)",
							fill: false,
							pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
							pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
							pointRadius: 2,
						},
						{
							label: "Luckytime_visit",
							data: apiRes.data.map((item: any) => item.luckytime_visit),
							backgroundColor: "rgba(75, 192, 192, 1)",
							borderColor: "rgba(54, 162, 235, 1)",
							fill: false,
							pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
							pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
							pointRadius: 2,
						},
						{
							label: "MatchMeUp",
							data: apiRes.data.map((item: any) => item.matchMeUp),
							backgroundColor: "rgba(75, 192, 192, 1)",
							borderColor: "rgba(54, 162, 235, 1)",
							fill: false,
							pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
							pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
							pointRadius: 2,
						},
					],
				};
			} else {
				featuresAccountChartData.value = {
					labels: [],
					type: "line",
					datasets: [
						{
							label: "Astro Visit",
							data: [],
							backgroundColor: "rgba(54, 162, 235, 1)",
							borderColor: "rgba(54, 162, 235, 1)",
							fill: false,
							pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
							pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
							pointRadius: 2,
						},
						{
							label: "Jugnian Visit",
							data: [],
							backgroundColor: "rgba(255, 99, 132, 1)",
							borderColor: "rgba(255, 99, 132, 1)",
							fill: false,
							pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
							pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
							pointRadius: 2,
						},
						{
							label: "Chatbot Visit",
							data: [],
							backgroundColor: "rgba(75, 192, 192, 1)",
							borderColor: "rgba(75, 192, 192, 1)",
							fill: false,
							pointBackgroundColor: "rgba(54, 162, 235, 1)", // Match line color
							pointBorderColor: "rgba(54, 162, 235, 1)", // Match line color
							pointRadius: 2,
						},
					],
				};
			}
			if (apiRes && apiRes?.totalVisitCount) {
				const totals = apiRes.totalVisitCount;
				featureAnalyticsPieChartData.value = {
					labels: Object.keys(totals).map((key) => {
						switch (key) {
							case "jugnian":
								return "MBTI Compatibility";
							case "romeu":
								return "Romeu";
							case "astro":
								return "Astro Compatibility";
							case "luckytime":
								return "Luckytime";
							case "matchMeUp":
								return "MatchMeUp";
							default:
								return key;
						}
					}),
					datasets: [
						{
							data: Object.values(totals),
							backgroundColor: ["rgba(255, 99, 132, 0.7)", "rgba(54, 162, 235, 0.7)", "rgba(255, 206, 86, 0.7)", "rgba(75, 192, 192, 0.7)", "rgba(153, 102, 255, 0.7)"],
							borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)"],
							borderWidth: 1,
						},
					],
				};
			}
			loading.value = false;
		} catch (error) {
			loading.value = false;
			openNotificationWithIcon("error", "Error fetching Visited Counts data");
		}
	};

	fetchFeaturesCountData();

	const handleVisitTypeChange = (selectedValues: string[]) => {
		fetchFeaturesCountData();
	};

	const handleTimeChange = (selectedValue: string) => {
		timeRange.value = selectedValue;

		if (timeRange.value === "day" || (timeRange.value === "month" && monthRangeValue.value)) {
			fetchFeaturesCountData();
		}
	};

	const handleMonthChange = (selectedValue: string | null) => {
		monthRangeValue.value = selectedValue;

		// Call API only if both conditions are met
		if (timeRange.value === "month" && monthRangeValue.value) {
			fetchFeaturesCountData();
		}
	};

	const featuredAnalyticsPieChartOptions: ChartOptions<"pie"> = {
		responsive: true,
		plugins: {
			tooltip: {
				enabled: false,
			},
			legend: {
				display: true,
				position: "right",
				labels: {
					color: "#000000",
					padding: 20,
					font: {
						size: 12,
					},
				},
			},
		},
	};

	const featureAnalyticsPieChartData = ref({
		labels: [] as string[],
		datasets: [
			{
				data: [] as number[],
				backgroundColor: ["rgba(255, 99, 132, 0.7)", "rgba(54, 162, 235, 0.7)", "rgba(255, 206, 86, 0.7)", "rgba(75, 192, 192, 0.7)", "rgba(153, 102, 255, 0.7)"],
				borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)"],
				borderWidth: 1,
			},
		],
	});
</script>

<style scoped>
	.analyticsHeading {
		font-size: 30px;
		display: flex;
		justify-content: center;
		color: #44142a;
		font-weight: 500;
	}
	.analyticsContainer {
		padding: 20px 100px;
	}
	.table-operations {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -11px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
		font-size: 15px;
	}
	.membersLocationChart {
		display: flex;
		gap: 70px;
	}
	.BarChart {
		width: 60%;
	}
	.PieChart {
		display: flex;
		flex-direction: column;
		width: 40%;
		height: 400px;
		justify-content: center;
		align-items: center;
	}
	.genderPieChart {
		width: 300px;
		height: 300px;
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 25px;
	}
	.mainDiv {
		padding: 20px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 6px;
		margin-bottom: 25px;
	}
	.genderDistributionContainer {
		padding: 40px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 6px;
		margin-bottom: 25px;
	}
	.genderDistributionChart {
		display: flex;
		gap: 70px;
		justify-content: space-between;
	}
	.selectOptions {
		display: flex;
		gap: 15px;
		justify-content: space-between;
	}

	.filter-dropdown {
		width: 250px;
	}
	.visitTypeSelect {
		margin-right: 10px;
		width: 9rem;
		margin-left: 3px;
	}
	.featureAnalytics {
		width: 100%;
	}
	.chartContainerDropDown {
		display: flex;
		margin-bottom: 24px;
		justify-content: space-between;
	}
	.chartContainerDropDownVisitOption {
		display: flex;
		margin-bottom: 24px;
		justify-content: space-between;
		align-items: center;
	}
	.selectOptions {
		display: flex;
		gap: 15px;
		align-items: center;
	}

	.visitTypeSelect,
	a-checkbox-group {
		display: flex;
		align-items: center;
		gap: 10px; /* Adds spacing between checkboxes */
	}

	a-label {
		white-space: nowrap; /* Prevents label from wrapping */
	}

	.chart-wrapper {
		display: flex;
		padding: 20px;
	}

	.pie-chart-container {
		width: 100%;
		max-width: 500px;
		height: 500px;
	}

	@media (max-width: 1600px) {
		.genderDistributionChart {
			gap: 40px;
		}
		.analyticsContainer {
			padding: 20px 50px;
		}
		.membersLocationChart {
			gap: 40px;
		}
		.featureAnalytics {
			width: 100%;
		}
	}
</style>

<template>
	<div v-if="loading" class="loading-wrapper">
		<a-spin />
	</div>
	<div v-if="!loading" class="AppSettingContainer">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item>Notification Settings</a-breadcrumb-item>
			</a-breadcrumb>
			<a-button type="primary" class="saveBtn" @click="saveData">Save</a-button>
		</div>
		<div class="mainContainer">
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }"> Incomplete user notification setting </span>
				</div>
				<div class="detailsContainer" :style="{ margin: '25px 0px' }">
					<span>1. Set Notification Reminder for Incomplete Users (hours)</span>
					<span>
						<a-input type="number" class="input" v-model:value="inCompleteNotificationSentResetInterval" addon-after="hour"></a-input>
					</span>
				</div>
				<div class="detailsContainer">
					<a-form-item label="2. Notification Redirection Key"> </a-form-item>
					<span>
						<a-select v-model:value="selectedNotificationKey" @change="handleSelectChangeNotifiCationKey" class="dropDown">
							<a-select-option v-for="notification in notifications" :key="notification.key" :value="notification.key">
								{{ notification.title }}
							</a-select-option>
						</a-select>
					</span>
				</div>
				<div class="detailsContainer" :style="{ margin: '25px 0px' }">
					<span>3. Notification Title </span>
					<span>
						<div :style="{ width: '35rem' }">
							<a-textarea :style="{ margin: '10px 0px' }" v-model:value="inCompleteNotificationSentTitle" :rows="2" />
						</div>
					</span>
				</div>
				<div class="detailsContainer" :style="{ margin: '25px 0px' }">
					<span>4. Notification Body </span>
					<span>
						<div :style="{ width: '35rem' }">
							<a-textarea :style="{ margin: '10px 0px' }" v-model:value="inCompleteNotificationSentBody" :rows="3" />
						</div>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { ref } from "vue";
	import { getNotificationKey, getNotificationSetting, putNotificationSetting } from "../services/api/user";
	import { openNotificationWithIcon } from "@/utils";
	const loading = ref(false);
	const inCompleteNotificationSentTitle = ref("");
	const inCompleteNotificationSentResetInterval: any = ref(0);
	const notifications: any = ref<Notification[]>([]);
	const inCompleteNotificationSentBody = ref("");
	const selectedNotificationKey = ref("");

	const fetchData = async () => {
		loading.value = true;
		const appSettingsRes = await getNotificationSetting();
		await appSettingsRes.items.forEach((appSettings: any) => {
			if (appSettings.key == "incomplete_notification_sent_reset_time") {
				inCompleteNotificationSentResetInterval.value = appSettings.value;
			}

			if (appSettings.key == "incomplete_notification_sent_title") {
				inCompleteNotificationSentTitle.value = appSettings.value;
			}

			if (appSettings.key == "incomplete_notification_sent_body") {
				inCompleteNotificationSentBody.value = appSettings.value;
			}
			if (appSettings.key == "incomplete_notification_key") {
				selectedNotificationKey.value = appSettings.value;
			}
		});
		loading.value = false;
	};
	fetchData();

	const saveData = async () => {
		try {
			const data: any = [
				{ key: "incomplete_notification_sent_reset_time", value: inCompleteNotificationSentResetInterval.value },
				{ key: "incomplete_notification_sent_title", value: inCompleteNotificationSentTitle.value },
				{ key: "incomplete_notification_sent_body", value: inCompleteNotificationSentBody.value },
				{ key: "incomplete_notification_key", value: selectedNotificationKey.value },
			];
			await putNotificationSetting(data);
			await fetchData();
			openNotificationWithIcon("success", "Notification settings updated successfully");
		} catch (error) {
			console.log(error);
			openNotificationWithIcon("error", "Something want wrong");
		}
	};

	const fetchNotificationKey = async () => {
		try {
			loading.value = true;
			const apiRes = await getNotificationKey();
			notifications.value = apiRes.key;
			console.log(notifications, 283);
			loading.value = false;
		} catch (error) {
			console.error("Error fetching data:", error);
			loading.value = false;
		}
	};
	fetchNotificationKey();

	const handleSelectChangeNotifiCationKey = (value: string) => {
		selectedNotificationKey.value = value;
	};
</script>
<style scoped>
	.AppSettingContainer {
		padding: 20px 40px;
	}
	.table-operations {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 30px;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
		color: #44142a !important;
	}
	.mainContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 5px;
	}
	.mainDiv {
		padding: 20px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 6px;
		width: 55%;
		margin-bottom: 25px;
	}
	.dropDown {
		width: 14rem;
	}
	.subDiv {
		display: flex;
		position: absolute;
		top: -11px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
		font-size: 15px;
	}
	.detailsContainer {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}
	.input {
		height: 2rem;
		width: 8rem;
	}
	.loading-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}
	.saveBtn {
		width: 100px;
	}
</style>

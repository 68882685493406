<template>
	<div class="analyticsContainer">
		<div>
			<div class="deletedAccountContainer" :style="{ marginBottom: '60px' }">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Deleted Accounts </span>
				</div>
				<div class="chartContainerDropDown">
					<div class="selectOptions">
						<a-select v-model:value="selectDeletedAccountYear" @change="fetchDeletedAccountData" style="width: 120px">
							<a-select-option v-for="year in availableYears()" :key="year" :value="year">
								{{ year }}
							</a-select-option>
						</a-select>
					</div>
				</div>
				<div class="deletedAccountChartContainer">
					<div class="BarChart">
						<div class="chartHeading">Deleted Counts</div>
						<Bar :data="DeletedAccountChartData" :options="deletedAccountChartOptions" />
					</div>
					<div class="PieChart">
						<Pie :data="totalUsersPieChartData" :options="TotalUsersPieChartOptions" style="margin-top: 15px" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ref } from "vue";
	import { Bar } from "vue-chartjs";
	import { Line, Pie } from "vue-chartjs";
	import ChartDataLabels from "chartjs-plugin-datalabels";
	import { Chart, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, PieController, ArcElement } from "chart.js";
	import { genderByMonth, deletedAccountByMonth, membersByLocation, visitedCount } from "@/services/api/user";
	import { ChartOptions } from "chart.js";
	import { openNotificationWithIcon } from "@/utils";
	import dayjs from "dayjs";
	Chart.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, PieController, ArcElement, ChartDataLabels);

	const loading = ref(false);
	const selectDeletedAccountYear: any = ref(new Date().getFullYear());
	function availableYears() {
		const startYear = 2022;
		const currentYear = new Date().getFullYear();
		const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);
		return years.reverse();
	}

	const deletedAccountChartOptions: ChartOptions<"bar"> = {
		responsive: true,
		layout: {
			padding: {
				top: 27,
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: "Numbers of Accounts",
					font: {
						size: 20,
					},
				},
			},
		},
		plugins: {
			tooltip: {
				enabled: false,
			},
			datalabels: {
				anchor: "end",
				align: "end",
				formatter: (value: any, context: any) => {
					// Only show label if value is greater than 0
					return value > 0 ? value : "";
				},
				font: {
					size: 12,
				},
			},
			legend: {
				display: true,
				position: "bottom",
			},
		},
	};
	const DeletedAccountChartData = ref({
		labels: [],
		type: "bar",
		datasets: [
			{
				label: "Male",
				data: [],
				backgroundColor: "rgba(54, 162, 235, 1)",
				borderColor: "rgba(54, 162, 235, 1)",
				fill: false,
			},
			{
				label: "Female",
				data: [],
				backgroundColor: "rgba(255, 99, 132, 1)",
				borderColor: "rgba(255, 99, 132, 1)",
				fill: false,
			},
			{
				label: "Total",
				data: [],
				backgroundColor: "rgba(75, 192, 192, 1)",
				borderColor: "rgba(75, 192, 192, 1)",
				fill: false,
			},
		],
	});
	const fetchDeletedAccountData = async () => {
		try {
			loading.value = true;
			const yearNumber = parseInt(selectDeletedAccountYear.value);
			const apiRes = await deletedAccountByMonth(yearNumber);
			if (apiRes && apiRes.deletedUsers === `No data found for the year ${yearNumber}`) {
				DeletedAccountChartData.value = {
					labels: [],
					type: "bar",
					datasets: [
						{
							label: "Male",
							data: [],
							backgroundColor: "rgba(54, 162, 235, 1)",
							borderColor: "rgba(54, 162, 235, 1)",
							fill: false,
						},
						{
							label: "Female",
							data: [],
							backgroundColor: "rgba(255, 99, 132, 1)",
							borderColor: "rgba(255, 99, 132, 1)",
							fill: false,
						},
						{
							label: "Total",
							data: [],
							backgroundColor: "rgba(75, 192, 192, 1)",
							borderColor: "rgba(75, 192, 192, 1)",
							fill: false,
						},
					],
				};
			} else {
				const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
				const labels = apiRes.deletedUsers.map((item: any) => `${monthNames[item.month - 1]} ${selectDeletedAccountYear.value}`);
				const maleCount = apiRes.deletedUsers.map((item: any) => item.counts.find((count: any) => count.gender === "m")?.count || 0);
				const femaleCount = apiRes.deletedUsers.map((item: any) => item.counts.find((count: any) => count.gender === "f")?.count || 0);
				const total = apiRes.deletedUsers.map((item: any) => item.total);
				DeletedAccountChartData.value = {
					labels: labels,
					type: "bar",
					datasets: [
						{
							label: "Male",
							data: maleCount,
							backgroundColor: "rgba(54, 162, 235, 1)",
							borderColor: "rgba(54, 162, 235, 1)",
							fill: false,
						},
						{
							label: "Female",
							data: femaleCount,
							backgroundColor: "rgba(255, 99, 132, 1)",
							borderColor: "rgba(255, 99, 132, 1)",
							fill: false,
						},
						{
							label: "Total",
							data: total,
							backgroundColor: "rgba(75, 192, 192, 1)",
							borderColor: "rgba(75, 192, 192, 1)",
							fill: false,
						},
					],
				};
			}
			if (apiRes && apiRes.totalUsers) {
				const totals = apiRes.totalUsers;
				totalUsersPieChartData.value = {
					labels: ["Male", "Female"],
					datasets: [
						{
							data: Object.values(totals),
							backgroundColor: ["rgba(255, 99, 132, 0.7)", "rgba(54, 162, 235, 0.7)"],
							borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
							borderWidth: 1,
						},
					],
				};
			}
			loading.value = false;
		} catch (error) {
			loading.value = false;
			openNotificationWithIcon("error", "Error fetching Deleted Account data");
		}
	};
	fetchDeletedAccountData();

	const TotalUsersPieChartOptions: ChartOptions<"pie"> = {
		responsive: true,
		plugins: {
			tooltip: {
				enabled: false,
			},
			legend: {
				display: true,
				position: "bottom",
				labels: {
					color: "#000000",
					padding: 20,
					font: {
						size: 12,
					},
				},
			},
		},
	};

	const totalUsersPieChartData = ref({
		labels: [] as string[],
		datasets: [
			{
				data: [] as number[],
				backgroundColor: ["rgba(255, 99, 132, 0.7)", "rgba(54, 162, 235, 0.7)"],
				borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
				borderWidth: 1,
			},
		],
	});
</script>

<style scoped>
	.analyticsContainer {
		padding: 20px 100px;
	}
	.table-operations {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -11px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
		font-size: 15px;
	}

	.BarChart {
		width: 60%;
	}
	.PieChart {
		display: flex;
		flex-direction: column;
		width: 40%;
		height: 400px;
		justify-content: center;
		align-items: center;
	}
	.deletedAccountChartContainer {
		display: flex;
	}
	.deletedAccountContainer {
		padding: 40px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 6px;
		margin-bottom: 25px;
	}

	.selectOptions {
		display: flex;
		gap: 15px;
	}
	.chartHeading {
		font-size: 22px;
		display: flex;
		justify-content: center;
		color: rgba(174, 174, 174, 255);
		margin-bottom: 10px;
	}

	.chartContainerDropDown {
		display: flex;
		margin-bottom: 24px;
		align-items: center; /* Ensures vertical alignment */
		gap: 10px; /* Adjusts spacing between elements */
	}

	.selectOptions {
		display: flex;
		gap: 15px;
		align-items: center;
	}

	a-label {
		white-space: nowrap; /* Prevents label from wrapping */
	}

	@media (max-width: 1600px) {
		.analyticsContainer {
			padding: 20px 50px;
		}
	}
</style>

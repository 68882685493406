<template>
	<div class="analyticsContainer">
		<div>
			<div class="membersLocationontainer" :style="{ marginBottom: '60px' }">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }"> Members location (all) </span>
				</div>
				<div class="chartContainerDropDown">
					<div class="selectOptions">
						<a-select v-model:value="selectMembersLimit" @change="membersByLocationData" style="width: 120px">
							<a-select-option value="10">Top 10</a-select-option>
							<a-select-option value="30">Top 30</a-select-option>
						</a-select>
						<a-select v-model:value="selectOptionForMembersLocation" @change="membersByLocationData" style="width: 120px">
							<a-select-option value="country">By Country</a-select-option>
							<a-select-option value="city">By City</a-select-option>
						</a-select>
					</div>
				</div>
				<div class="membersLocationChart">
					<div class="BarChart">
						<div class="chartHeading">Members Location</div>
						<Bar :data="membersLocationChartData" :options="membersLocationBarChartOptions" />
					</div>
					<div class="PieChart">
						<div class="chartHeading">Members -Top Locations</div>
						<Pie id="pie-chart" class="pieChart" :data="membersLocationPieChartData" :options="membersLocationPieChartOptions" />
					</div>
				</div>
			</div>
			<div class="genderDistributionContainer" :style="{ marginBottom: '60px' }">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }"> Members by Genders </span>
				</div>
				<div class="chartContainerDropDown">
					<div class="selectOptions">
						<a-select v-model:value="selectGenderByYear" @change="fetchGenderData" style="width: 120px">
							<a-select-option v-for="year in availableYears()" :key="year" :value="year">
								{{ year }}
							</a-select-option>
						</a-select>
					</div>
				</div>
				<div class="genderDistributionChart">
					<div class="BarChart">
						<div class="chartHeading">Female Vs Male Members</div>
						<Line id="gender-chart" :data="genderChartData" :options="genderLineChartOptions" />
					</div>
					<div class="PieChart" style="height: 350px">
						<div class="chartHeading">Gender Distributuion</div>
						<Pie :data="genderPieChartData" :options="genderPieChartOptions" style="margin-top: 15px" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ref } from "vue";
	import { Bar } from "vue-chartjs";
	import { Line, Pie } from "vue-chartjs";
	import ChartDataLabels from "chartjs-plugin-datalabels";
	import { Chart, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, PieController, ArcElement } from "chart.js";
	import { genderByMonth, membersByLocation } from "@/services/api/user";
	import { ChartOptions } from "chart.js";
	import { openNotificationWithIcon } from "@/utils";
	Chart.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, PieController, ArcElement, ChartDataLabels);

	const selectOptionForMembersLocation = ref("country");
	const selectMembersLimit = ref<string>("10");
	const loading = ref(false);
	const selectGenderByYear: any = ref(new Date().getFullYear());

	function availableYears() {
		const startYear = 2022;
		const currentYear = new Date().getFullYear();
		const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);
		return years.reverse();
	}

	const membersLocationBarChartOptions: ChartOptions<"bar"> = {
		responsive: true,
		layout: {
			padding: {
				top: 27,
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
		},
		plugins: {
			tooltip: {
				enabled: false,
			},
			datalabels: {
				anchor: "end",
				align: "end",
				formatter: (value: any, context: any) => {
					return value;
				},
				font: {
					size: 12,
				},
			},
			legend: {
				display: true,
				position: "bottom",
			},
		},
	};
	const membersLocationChartData = ref({
		labels: [],
		type: "bar",
		datasets: [
			{
				label: "Members Location",
				data: [],
				borderColor: "rgba(54, 162, 235, 1)",
				backgroundColor: "rgba(54, 162, 235, 1)",
				fill: false,
			},
		],
	});

	const membersLocationPieChartOptions: ChartOptions<"pie"> = {
		responsive: true,
		layout: {
			padding: {
				top: 10,
			},
		},
		plugins: {
			tooltip: {
				enabled: false,
			},
			legend: {
				display: true,
				position: "bottom",
				labels: {
					color: "#000000",
					padding: 20,
					font: {
						size: 12,
					},
				},
			},
		},
	};
	const membersLocationPieChartData = ref({
		labels: ["members"],
		type: "pie",
		datasets: [
			{
				data: [],
				backgroundColor: ["rgba(102,232,205,0.5)", "rgba(241,138,217,0.5)", "rgba(248,178,66,0.5)", "rgba(50,165,185,0.5)", "rgba(133,193,233,0.5)"],
				borderColor: ["rgba(102,232,205,1)", "rgba(241,138,217,1)", "rgba(248,178,66,1)", "rgba(50,165,185,1)", "rgba(133,193,233,1)"],
				borderWidth: 1,
			},
		],
	});

	const membersByLocationData = async () => {
		try {
			loading.value = true;
			const limitNumber = Number(selectMembersLimit.value);
			const apiRes = await membersByLocation(selectOptionForMembersLocation.value, limitNumber);
			let topDataForPieChart = apiRes.locationData.sort((a: any, b: any) => b.count - a.count).slice(0, 5);
			const capitalizeFirstLetter = (str: string) => {
				return str.charAt(0).toUpperCase() + str.slice(1);
			};
			if (selectOptionForMembersLocation.value === "country") {
				if (apiRes && apiRes.locationData && apiRes.locationData.length > 0) {
					(membersLocationChartData.value = {
						labels: apiRes.locationData.map((item: any) => capitalizeFirstLetter(item.country)),
						type: "bar",
						datasets: [
							{
								label: "Members",
								data: apiRes.locationData.map((item: any) => item.count),
								backgroundColor: "rgba(54, 162, 235, 1)",
								borderColor: "rgba(54, 162, 235, 1)",
								fill: false,
							},
						],
					}),
						(membersLocationPieChartData.value = {
							labels: topDataForPieChart.map((item: any) => capitalizeFirstLetter(item.country)),
							type: "pie",
							datasets: [
								{
									data: topDataForPieChart.map((item: any) => item.count),
									backgroundColor: ["rgba(102,232,205,0.5)", "rgba(241,138,217,0.5)", "rgba(248,178,66,0.5)", "rgba(50,165,185,0.5)", "rgba(133,193,233,0.5)"],
									borderColor: ["rgba(102,232,205,1)", "rgba(241,138,217,1)", "rgba(248,178,66,1)", "rgba(50,165,185,1)", "rgba(133,193,233,1)"],
									borderWidth: 1,
								},
							],
						});
				} else {
					membersLocationChartData.value = {
						labels: [],
						type: "bar",
						datasets: [
							{
								label: "Members Location",
								data: [],
								backgroundColor: "rgba(54, 162, 235, 1)",
								borderColor: "rgba(54, 162, 235, 1)",
								fill: false,
							},
						],
					};
					membersLocationPieChartData.value = {
						labels: [],
						type: "pie",
						datasets: [
							{
								data: [],
								backgroundColor: ["rgba(102,232,205,0.5)", "rgba(241,138,217,0.5)", "rgba(248,178,66,0.5)", "rgba(50,165,185,0.5)", "rgba(133,193,233,0.5)"],
								borderColor: ["rgba(102,232,205,1)", "rgba(241,138,217,1)", "rgba(248,178,66,1)", "rgba(50,165,185,1)", "rgba(133,193,233,1)"],
								borderWidth: 1,
							},
						],
					};
				}
			} else if (selectOptionForMembersLocation.value === "city") {
				if (apiRes && apiRes.locationData && apiRes.locationData.length > 0) {
					(membersLocationChartData.value = {
						labels: apiRes.locationData.map((item: any) => capitalizeFirstLetter(item.city)),
						type: "bar",
						datasets: [
							{
								label: "Members",
								data: apiRes.locationData.map((item: any) => item.count),
								backgroundColor: "rgba(54, 162, 235, 1)",
								borderColor: "rgba(54, 162, 235, 1)",
								fill: false,
							},
						],
					}),
						(membersLocationPieChartData.value = {
							labels: topDataForPieChart.map((item: any) => capitalizeFirstLetter(item.city)),
							type: "pie",
							datasets: [
								{
									data: topDataForPieChart.map((item: any) => item.count),
									backgroundColor: ["rgba(102,232,205,0.5)", "rgba(241,138,217,0.5)", "rgba(248,178,66,0.5)", "rgba(50,165,185,0.5)", "rgba(133,193,233,0.5)"],
									borderColor: ["rgba(102,232,205,1)", "rgba(241,138,217,1)", "rgba(248,178,66,1)", "rgba(50,165,185,1)", "rgba(133,193,233,1)"],
									borderWidth: 1,
								},
							],
						});
				} else {
					(membersLocationChartData.value = {
						labels: [],
						type: "bar",
						datasets: [
							{
								label: "Members Location",
								data: [],
								backgroundColor: "rgba(54, 162, 235, 1)",
								borderColor: "rgba(54, 162, 235, 1)",
								fill: false,
							},
						],
					}),
						(membersLocationPieChartData.value = {
							labels: [],
							type: "pie",
							datasets: [
								{
									data: [],
									backgroundColor: ["rgba(102,232,205,0.5)", "rgba(241,138,217,0.5)", "rgba(248,178,66,0.5)", "rgba(50,165,185,0.5)", "rgba(133,193,233,0.5)"],
									borderColor: ["rgba(102,232,205,1)", "rgba(241,138,217,1)", "rgba(248,178,66,1)", "rgba(50,165,185,1)", "rgba(133,193,233,1)"],
									borderWidth: 1,
								},
							],
						});
				}
			}
			loading.value = false;
		} catch (error) {
			loading.value = false;
			openNotificationWithIcon("error", "Error fetching Members location data");
		}
	};
	membersByLocationData();

	const genderLineChartOptions: ChartOptions<"line"> = {
		responsive: true,
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
		},
		plugins: {
			tooltip: {
				enabled: false,
			},
			datalabels: {
				display: false,
			},
			legend: {
				display: true,
				position: "bottom",
				labels: {
					color: "#000000",
					padding: 20,
					font: {
						size: 12,
					},
				},
			},
		},
	};

	const genderChartData = ref({
		labels: [],
		type: "line",
		datasets: [
			{
				label: "Male",
				data: [],
				borderColor: "rgba(54, 162, 235, 1)",
				backgroundColor: "rgba(54, 162, 235, 1)",
				fill: false,
			},
			{
				label: "Female",
				data: [],
				borderColor: "rgba(255, 99, 132, 1)",
				backgroundColor: "rgba(255, 99, 132, 1)",
				fill: false,
			},
		],
	});
	const fetchGenderData = async () => {
		try {
			loading.value = true;
			const yearNumber = parseInt(selectGenderByYear.value);
			const apiRes = await genderByMonth(yearNumber);
			if (apiRes && apiRes.maleAndFemaleMembers === `No data found for the year ${yearNumber}`) {
				genderChartData.value = {
					labels: [],
					type: "line",
					datasets: [
						{
							label: "Male",
							data: [],
							borderColor: "rgba(54, 162, 235, 1)",
							backgroundColor: "rgba(54, 162, 235, 1)",
							fill: false,
						},
						{
							label: "Female",
							data: [],
							borderColor: "rgba(255, 99, 132, 1)",
							backgroundColor: "rgba(255, 99, 132, 1)",
							fill: false,
						},
					],
				};
			} else {
				const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
				const labels = apiRes.maleAndFemaleMembers.map((item: any) => {
					const month = Number(item._id);
					return `${monthNames[month - 1]} ${selectGenderByYear.value}`;
				});
				genderChartData.value = {
					labels: labels,
					type: "line",
					datasets: [
						{
							label: "Male",
							data: apiRes.maleAndFemaleMembers.map((item: any) => item.maleCount),
							backgroundColor: "rgba(54, 162, 235, 1)",
							borderColor: "rgba(54, 162, 235, 1)",
							fill: false,
						},
						{
							label: "Female",
							data: apiRes.maleAndFemaleMembers.map((item: any) => item.femaleCount),
							backgroundColor: "rgba(255, 99, 132, 1)",
							borderColor: "rgba(255, 99, 132, 1)",
							fill: false,
						},
					],
				};
			}
			loading.value = false;
		} catch (error) {
			loading.value = false;
		}
	};
	fetchGenderData();

	const genderPieChartOptions: ChartOptions<"pie"> = {
		responsive: true,
		plugins: {
			tooltip: {
				enabled: false,
			},
			legend: {
				display: true,
				position: "bottom",
				labels: {
					color: "#000000",
					padding: 20,
					font: {
						size: 12,
					},
				},
			},
		},
	};
	const genderPieChartData = ref<{
		labels: string[];
		type: string;
		datasets: {
			data: number[];
			backgroundColor: string[];
			borderColor: string[];
			fill: boolean;
		}[];
	}>({
		labels: ["Male", "Female"],
		type: "pie",
		datasets: [
			{
				data: [0, 0],
				backgroundColor: ["rgba(54, 162, 235, 0.5)", "rgba(255, 99, 132, 0.5)"],
				borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
				fill: false,
			},
		],
	});
	const genderPieChart = async () => {
		try {
			loading.value = true;
			const yearNumber = parseInt(selectGenderByYear.value);
			const apiRes = await genderByMonth(yearNumber);
			if (apiRes && apiRes.totalMaleAndFemale) {
				const maleCount = apiRes.totalMaleAndFemale.totalMaleCount;
				const femaleCount = apiRes.totalMaleAndFemale.totalFemaleCount;
				genderPieChartData.value = {
					labels: ["Male", "Female"],
					type: "pie",
					datasets: [
						{
							data: [Number(maleCount), Number(femaleCount)],
							backgroundColor: ["rgba(102, 232, 205, 0.5)", "rgba(241, 138, 217, 0.5)"],
							borderColor: ["rgba(102, 232, 205, 1)", "rgba(241, 138, 217, 1)"],
							fill: false,
						},
					],
				};
			} else {
				openNotificationWithIcon("error", "No male and female count data found");
			}

			loading.value = false;
		} catch (error) {
			loading.value = false;
			openNotificationWithIcon("error", "Error fetching Gender data ");
		}
	};
	genderPieChart();
</script>

<style scoped>
	.analyticsContainer {
		padding: 20px 100px;
	}
	.table-operations {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	.membersLocationontainer {
		padding: 40px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 6px;
		margin-bottom: 25px;
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -11px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
		font-size: 15px;
	}

	.membersLocationChart {
		display: flex;
		gap: 70px;
	}

	.BarChart {
		width: 60%;
	}

	.PieChart {
		display: flex;
		flex-direction: column;
		width: 40%;
		height: 400px;
		justify-content: center;
		align-items: center;
	}

	.genderPieChart {
		width: 300px;
		height: 300px;
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 25px;
	}

	.genderDistributionContainer {
		padding: 40px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 6px;
		margin-bottom: 25px;
	}
	.genderDistributionChart {
		display: flex;
		gap: 70px;
		justify-content: space-between;
	}
	.selectOptions {
		display: flex;
		gap: 15px;
	}
	.chartHeading {
		font-size: 22px;
		display: flex;
		justify-content: center;
		color: rgba(174, 174, 174, 255);
		margin-bottom: 10px;
	}

	.chartContainerDropDown {
		display: flex;
		margin-bottom: 24px;
		align-items: center; /* Ensures vertical alignment */
		gap: 10px; /* Adjusts spacing between elements */
	}

	.selectOptions {
		display: flex;
		gap: 15px;
		align-items: center;
	}

	a-label {
		white-space: nowrap; /* Prevents label from wrapping */
	}

	@media (max-width: 1600px) {
		.genderDistributionChart {
			gap: 40px;
		}
		.analyticsContainer {
			padding: 20px 50px;
		}
		.membersLocationChart {
			gap: 40px;
		}
	}
</style>

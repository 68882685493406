<template>
	<div v-if="loading" class="loading-wrapper">
		<a-spin />
	</div>
	<div v-if="!loading" class="ChatbotSettingContainer">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item>Romeu Settings</a-breadcrumb-item>
			</a-breadcrumb>
			<a-button type="primary" class="saveBtn" @click="saveData">Save</a-button>
		</div>
		<div class="mainContainer">
			<div class="mainDiv">
				<div class="subDiv">
					<span :style="{ margin: '0px 5px' }">Manage Romeu</span>
				</div>
				<div class="detailsContainer" :style="{ marginBottom: '30px', marginTop: '10px' }">
					<span>1. Is Romeu Enable</span>
					<div>
						<a-switch v-model:checked="chatbotVisit" />
					</div>
				</div>
				<div class="detailsContainer" :style="{ margin: '25px 0px' }">
					<span>2. Romeu Prompt</span>
					<span>
						<div :style="{ width: '35rem' }">
							<a-textarea :style="{ margin: '10px 0px' }" v-model:value="chatbotPrompt" :rows="8" />
						</div>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { ref } from "vue";
	import { getChatbotSetting, putChatbotSetting } from "../services/api/user";
	import { openNotificationWithIcon } from "@/utils";

	const loading = ref(false);
	const chatbotVisit = ref(false);
	const chatbotPrompt = ref("");

	const fetchData = async () => {
		loading.value = true;
		const appSettingsRes = await getChatbotSetting();
		await appSettingsRes.items.forEach((appSettings: any) => {
			if (appSettings.key == "is_chatbot_enable") {
				chatbotVisit.value = appSettings.value;
			}
			if (appSettings.key == "chatbot_prompt") {
				chatbotPrompt.value = appSettings.value;
			}
		});
		loading.value = false;
	};
	fetchData();

	const saveData = async () => {
		try {
			const data: any = [
				{ key: "is_chatbot_enable", value: chatbotVisit.value },
				{ key: "chatbot_prompt", value: chatbotPrompt.value },
			];
			await putChatbotSetting(data);
			await fetchData();
			openNotificationWithIcon("success", "Romeu settings updated successfully");
		} catch (error) {
			console.log(error);
			openNotificationWithIcon("error", "Something want wrong");
		}
	};
</script>
<style scoped>
	.ChatbotSettingContainer {
		padding: 20px 40px;
	}
	.table-operations {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 30px;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
		color: #44142a !important;
	}
	.mainContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 5px;
	}
	.mainDiv {
		padding: 20px;
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 6px;
		width: 55%;
		margin-bottom: 25px;
	}
	.dropDown {
		width: 14rem;
	}
	.subDiv {
		display: flex;
		position: absolute;
		top: -11px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
		font-size: 15px;
	}
	.detailsContainer {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}

	.loading-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}
	.saveBtn {
		width: 100px;
	}

	@media (max-width: 1600px) {
		.mainDiv {
			width: 75%;
		}
	}
</style>
